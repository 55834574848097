const iframeWidgteExternalMain = document.getElementById('iframe-widget-external-schedule-appointment');
const urlMain = iframeWidgteExternalMain.dataset.urlMain;
const buttonSound = new Audio;

window.addEventListener('DOMContentLoaded', function () {

    moveMainWidgetExternal();

    // Comprobando si exte un audio para asignar un evento en los botones.
    setAudioInit()

    initButtonActionWidgetExternal();

    // Listen to message from child window
    window.addEventListener('message', function (event) {
        // console.log(event, event.data);

        if (!iframeWidgteExternalMain) return;

        // if(event.data === 'subscribe'){
        //     console.log('Set subscribe event');
        // }

        if (event.data == 'open-widget-schedule-appointment') {
            showToogleWidget(true);
        }

        else if (event.data == 'close-widget-schedule-appointment') {
            showToogleWidget(false);
        }

        // Evento del iframe, cambio del url, se comprueba el url del iframe si es diferente para ocultar el widget
        // y si el url es diferente se rdirecciona el parent.
        else if (event.data == 'iFrame-url-redirect') {
            const urliFrameActual = iframeWidgteExternalMain.src;


            if (!urliFrameActual || !urlMain || urliFrameActual.indexOf(urlMain) !== -1) return;

            // alert('iframe cambio');

            // iframeWidgteExternalMain.src = urlMain;

            // window.location.href = urliFrameActual;
        }

    }, false);
    
    checkIfIFrameWidgetExternalHasAttributes();
});

function moveMainWidgetExternal()
{
    if( !iframeWidgteExternalMain ) return;

    document.body.appendChild( iframeWidgteExternalMain );
}

/**
 * Comprobando si exte un audio para asignar un evento en los botones.
 * @returns 
 */
function setAudioInit()
{
    if( !iframeWidgteExternalMain ) return;

    if( iframeWidgteExternalMain.dataset.audioButton == undefined || iframeWidgteExternalMain.dataset.audioButton == "" ) return;

    buttonSound.src = iframeWidgteExternalMain.dataset.audioButton;
    buttonSound.pause();
    buttonSound.currentTime = 0;    
}

/**
 * Reinicia la posición de preoducción del audio e inicia la reproducción.
 * @returns 
 */
function playInitButtonAudio()
{
    if( buttonSound.src == "" ) return;

    buttonSound.currentTime = 0;
    buttonSound.play();
}

/**
 * Reinicia la posición de preoducción del audio e inicia la reproducción.
 * @returns 
 */
function restartInitButtonAudio()
{
    if( buttonSound.src == "" ) return;

    buttonSound.pause();
    buttonSound.currentTime = 0;
}

function initButtonActionWidgetExternal()
{
    const classNameButton = 'btn-option-widget-external-shedule-appointment';
    const allButtons = document.getElementsByClassName( classNameButton );

    if( allButtons.length < 1 ) return;

    // Comprobando si existe un audio 

    for (let index = 0; index < allButtons.length; index++) {
        const element = allButtons[index];
        
        element.addEventListener('click', function ( event ) {
            // console.log( event );
            playInitButtonAudio();
            toggleClassShakeAnimationForButtons( event, classNameButton );

            setTimeout( () => {
                playInitButtonAudio();
                toggleClassShakeAnimationForButtons( event, classNameButton );

                setTimeout( () => restartInitButtonAudio(), 1650 );

            }, 1500 );

            setTimeout( () =>
                showContentWidgetInIFrame( true ), 1800 
            );
        });
    }
}

/**
 * 
 * @param {*} eventItem 
 */
function toggleClassShakeAnimationForButtons( eventItem, classNameButton )
{
    let elementButton = null;

    if( eventItem.target.classList.contains( classNameButton ) )
    {
        elementButton = eventItem.target;
        // console.log("a la primera el button")
    }
    
    else if( eventItem.target.parentNode.classList.contains( classNameButton )  )
    {
        elementButton = eventItem.target.parentNode;
        // console.log( "encontre al parent", elementButton );
    }
    
    if( elementButton )
    {
        elementButton.classList.add( 'shake-button' );
        setTimeout( () => elementButton.classList.remove( 'shake-button' ), 500 );
    }
}

function showContentWidgetInIFrame(show = false) {
    if (!iframeWidgteExternalMain) return;

    const messageInIFrame = show ? 'open-widget-in-iframe-schedule-appointment' : 'close-widget-in-iframe-schedule-appointment';

    iframeWidgteExternalMain.contentWindow.postMessage(
        messageInIFrame, '*'
    );
}


function showToogleWidget(show = false) {
    if (show) {
        iframeWidgteExternalMain.classList.toggle( 'hide-widget-shedule-appointment-external-busswe', false );
        iframeWidgteExternalMain.classList.toggle( 'show-widget-schedule-appointment-external-busswe', true );
    }
    else {
        iframeWidgteExternalMain.classList.toggle( 'show-widget-schedule-appointment-external-busswe', false );
        iframeWidgteExternalMain.classList.toggle( 'hide-widget-shedule-appointment-external-busswe', true );
    }
}

/**
 * Verifica que el iframe que muestra el widget externo tiene los atributos requeridos, 
 * si no los tiene se le agrega y se vuelve a crear el iframe para que tome los cambios.
 */
function checkIfIFrameWidgetExternalHasAttributes()
{
    // Verifica que iframe exista.
    if( !iframeWidgteExternalMain ) return;

    if( iframeWidgteExternalMain.getAttribute('allow') == undefined || iframeWidgteExternalMain.getAttribute('allow') != "autoplay" )
    {
        // Se le asigna al iframe el atributo Allow con el valor autoplay.
        // Para permitir en los moviles reproducir audios sin la necesidad que el usuario interactue primero dentro del iframe.
        iframeWidgteExternalMain.setAttribute('allow', 'autoplay');

        // Como el nuevo atributo no toma efecto a menos que se recargue la pagina principal, se realizara una copia del elemento.
        const copyIframe = iframeWidgteExternalMain;

        // Se elimina el elemento iframe.
        iframeWidgteExternalMain.remove();

        // Utilizando la copia el iframe se vuelve a asignar al final del body.
        document.body.appendChild( copyIframe );
    }

}